import PropTypes from 'prop-types';

import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import InboxOutlinedIcon from '@mui/icons-material/InboxOutlined';
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import { Badge } from '@mui/material';

import {
  Container,
  StyledButton,
} from './styles';

export default function SidebarSections({
  activeSection,
  onSectionClick,
  openedTickets,
  userChatQueue,
}) {
  return (
    <Container>
      {/*
      <StyledButton
        type="button"
        issectionactive={activeSection === 'Interno'}
        onClick={() => onSectionClick('Interno')}
      >
        <ForumOutlinedIcon />
        Interno
      </StyledButton>
      */}
      <StyledButton
        type="button"
        issectionactive={activeSection === 'Meus'}
        onClick={() => onSectionClick('Meus')}
      >
        <Badge
          badgeContent={openedTickets}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          color="primary"
        >
          <ChatOutlinedIcon />
        </Badge>
        Meus
      </StyledButton>
      <StyledButton
        type="button"
        issectionactive={activeSection === 'Aguardando'}
        onClick={() => onSectionClick('Aguardando')}
      >
        <Badge
          badgeContent={userChatQueue}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          color="error"
        >
          <InboxOutlinedIcon />
        </Badge>
        Aguardando
      </StyledButton>
      <StyledButton
        type="button"
        issectionactive={activeSection === 'Geral'}
        onClick={() => onSectionClick('Geral')}
      >
        <ContactPageOutlinedIcon />
        Geral
      </StyledButton>
    </Container>
  );
}

SidebarSections.propTypes = {
  activeSection: PropTypes.string.isRequired,
  onSectionClick: PropTypes.func.isRequired,
  openedTickets: PropTypes.number.isRequired,
  userChatQueue: PropTypes.number.isRequired,
};

export default {
  backgroundColor: '#EDEDED',
  primaryColor: '#FF6B35',
  secondaryColor: '#014F6D',
  blueTelein: '#0098d9',
  inputBackground: '#E1E8F2',
  buttonBackground: '#F5F5F5',
  hoverButtonBackground: '#EBEBEB',
  iconsColor: '#919191',
  secondaryFontColor: '#525252',
  dangerButton: '#FF0F0F',
  backgroundNotificationColor: '#DCE0E8',
  sidebarQuotedMessageColor: '#06CF9C',
  backgroundOriginLeadChip: '#FF8C42',
  backgroundCardKanban: '#E6Eff7',
  cardKanbanTitle: '#01374D',
  kanbanCardPriorityLow: '#4CAF50',
  kanbanCardPriorityMedium: '#FFCC22',
  kanbanCardPriorityHigh: '#FF0F0F',
};

export const breakpoints = {
  mobile: '480px',
  tablet: '768px',
  desktop: '1024px',
};

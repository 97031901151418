/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import { Badge, Tooltip } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import VideocamIcon from '@mui/icons-material/Videocam';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CheckIcon from '@mui/icons-material/Check';
import { useContext } from 'react';
import moment from 'moment';
import { fixEncodingStrings } from '../../../utils/services';
import { Container } from './styles';
import { ChatContext } from '../../../context/ChatContext';

export default function InternalSidebarChat({
  chat,
  isChatActive = false,
  profileImageURL = 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/1024px-User-avatar.svg.png?20201213175635',
}) {
  const {
    markThisChatNotificationsAsRead,
    setIsEditingProfile,
    setSelectedImageToSend,
    setUrlImageToSend,
  } = useContext(ChatContext);

  const handleSidebarChatClick = () => {
    setIsEditingProfile(false);
    markThisChatNotificationsAsRead(chat);
    setSelectedImageToSend(null);
    setUrlImageToSend([]);
  };

  const formatMessageBody = (message) => {
    if (!message) return;

    const regex = /^\*(.*?)\*:\s*/;
    const match = message.match(regex);
    if (match) {
      const boldText = match[1];
      const remainingText = message.replace(regex, '');

      return fixEncodingStrings(`${boldText}: ${remainingText}`);
    }

    return fixEncodingStrings(message);
  };

  const contactName = chat.memberName;

  return (
    <Container
      onClick={handleSidebarChatClick}
      style={{
        backgroundColor: isChatActive
          ? '#EBEBEB'
          : '#F5F5F5',
      }}
    >
      <img src={profileImageURL} alt="Imagem de usuário do chat" />

      <div
        className="sidebar-chat-container"
      >

        <div
          className="name-and-phone-div"
        >

          <Tooltip
            placement="top"
            arrow
            title={contactName}
          >
            <h1>{contactName}</h1>
          </Tooltip>
          <Tooltip
            arrow
            placement="top"
            title={chat.memberName}
          >
            <span
              style={{
                marginLeft: '0px',
                fontWeight: chat.unreadCount > 0 ? '700' : '400',
                color: 'black',
                paddingLeft: '5px',
              }}
            />
          </Tooltip>
        </div>
        <div
          className="last-message-container"
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          {
          chat.fromMe === 1 && (
            <CheckIcon style={{
              color: chat.ack >= 3
                ? '#53BDEB'
                : '#919191',
              fontSize: '20px',
            }}
            />
          )
        }

          {
          chat.fromMe === 1
          && chat.ack >= 2 && (
          <CheckIcon style={{
            color: chat.ack >= 3
              ? '#53BDEB'
              : '#919191',
            fontSize: '20px',
            marginLeft: '-15px',
          }}
          />
          )
          }

          {
          chat.mediaType?.startsWith('video') && (
          <div className="type-message-tag">
            <VideocamIcon
              style={{
                color: '#919191',
                fontSize: '20px',
              }}
            />
            <span
              style={{
                marginLeft: '0px',
                fontWeight: chat.unreadCount > 0 ? '700' : '400',
                color: 'black',
              }}
            >
              {!chat.lastMessage && ('Vídeo')}
            </span>
          </div>
          )
        }

          {
          chat.mediaType?.startsWith('audio') && (
            <div className="type-message-tag">
              <KeyboardVoiceIcon
                style={{
                  color: '#919191',
                  fontSize: '20px',
                }}
              />
              <span
                style={{
                  marginLeft: '0px',
                  fontWeight: chat.unreadCount > 0 ? '700' : '400',
                  color: 'black',
                }}
              >
                Áudio
              </span>
            </div>
          )
        }
          {
          chat.mediaType?.startsWith('application')
          && (
            <div className="type-message-tag">
              <InsertDriveFileIcon
                style={{
                  color: '#919191',
                  fontSize: '20px',
                }}
              />
              <span
                style={{
                  marginLeft: '5px',
                  fontWeight: chat.unreadCount > 0 ? '700' : '400',
                  color: 'black',
                }}
              >
                {!chat?.lastMessage && ('Documento')}
              </span>
            </div>
          )
        }
          {
          chat.mediaType?.startsWith('image')
            ? (
              <div
                className="image-message-tag"
                style={{
                  display: 'flex',
                  alignItems: 'end',
                  justifyContent: 'center',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                <CameraAltIcon style={{
                  color: '#919191',
                  fontSize: '20px',
                }}
                />
                <span style={{
                  marginLeft: '0px',
                  fontWeight: chat.unreadCount > 0 ? '700' : '400',
                  color: 'black',
                }}
                >
                  {
                  chat.mediaType?.startsWith('image')
                    ? 'Foto'
                    : chat?.lastMessage
                  }
                </span>
              </div>
            )
            : (
              <span
                style={{
                  marginLeft: '0px',
                  fontWeight: chat.unreadCount > 0 ? '700' : '400',
                  color: 'black',
                }}
              >
                {!chat.mediaType?.startsWith('audio')
                && (formatMessageBody(chat?.lastMessage))}

              </span>
            )
        }

        </div>

      </div>
      <div
        className="time-and-notifications-container"
      >
        <div className="notifications-div">

          <Badge
            badgeContent={chat.unreadCount}
            color="error"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: '5px',
              width: '20px',
              paddingLeft: '3px',
            }}
          >
            <NotificationsIcon style={{
              display: 'flex',
              color: '#919191',
              alignItems: 'center',
              fontSize: '25px',
            }}
            />
          </Badge>
        </div>
        <p>
          {
         chat.createdAt
           ? moment(chat.createdAt).calendar()
           : ''
            }
        </p>
      </div>
    </Container>
  );
}

InternalSidebarChat.propTypes = {
  chat: PropTypes.shape({
    body: PropTypes.string.isRequired,
    fromMe: PropTypes.number.isRequired,
    ack: PropTypes.number.isRequired,
    fromMsg: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    isDeleted: PropTypes.string,
    mediaUrl: PropTypes.string,
  }).isRequired,
  isChatActive: PropTypes.bool,
};

import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import { StyledButton } from './styles';

export default function CustomDialog({
  open,
  onClose,
  onConfirm,
  contactName = '',
  message,
  enableConfirmButton = true,
  actionName,
  children = null,
  confirmButtonColor = 'gray'
  ,
}) {
  const handleConfirm = () => {
    onConfirm();
    onClose();
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle
        style={{
          fontFamily: 'Montserrat, sans-serif',
          textAlign: 'center',
          fontSize: '24px',
          fontWeight: '700',
        }}
      >
        {message}
        {' '}
        {contactName}
      </DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions>
        <StyledButton
          onClick={onClose}
          type="button"
        >
          Cancelar
        </StyledButton>

        <StyledButton
          disabled={!enableConfirmButton}
          type="button"
          onClick={handleConfirm}
          style={{
            backgroundColor: confirmButtonColor,
          }}
        >
          {actionName}
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
}

CustomDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  actionName: PropTypes.string.isRequired,
  contactName: PropTypes.string,
  enableConfirmButton: PropTypes.bool,
  children: PropTypes.node,
  confirmButtonColor: PropTypes.string,
};

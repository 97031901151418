/* eslint-disable react/prop-types */
// KanbanCard.js
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import dayjs from 'dayjs';
import { useDrag } from 'react-dnd';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArchiveIcon from '@mui/icons-material/Archive';
import EventIcon from '@mui/icons-material/Event';
import ChatIcon from '@mui/icons-material/Chat';
import moment from 'moment';

import { Tooltip } from '@mui/material';
import { useTheme } from 'styled-components';
import ScheduleMessageDialog from '../ScheduleMessageDialog';
import CustomDialog from '../CustomDialog';
import {
  stripPrefix, baseUrl, formatPhoneNumber, getRequest, patchRequest,
} from '../../utils/services';
import CardDetailsDialog from './CardDetailsDialog';
import {
  CardContainer,
  CardLeftLine,
  CardHeader,
  AvatarImg,
  NameAndPhoneWrapper,
  CardMain,
  CardTagSection,
  CardTag,
  CardFooter,
  CardActionsWrapper,
  DueDateSection,
} from './KanbanCardStyles';

import { ChatContext } from '../../context/ChatContext';
import { useErrors } from '../../context/ErrorContext';

export default function KanbanCard({ card, columnId }) {
  const [{ isDragging }, drag] = useDrag({
    type: 'CARD',
    item: { id: card.id, columnId, type: 'CARD' },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const {
    setCurrentChat,
    setKanban,
    allUsers,
    fetchMessages,
  } = useContext(ChatContext);

  const [openScheduleMessage, setOpenScheduleMessage] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [openArchiveChat, setOpenArchiveChat] = useState(false);

  const theme = useTheme();

  const { setErrorMessage } = useErrors();

  const handleOpenArchiveChat = () => {
    setOpenArchiveChat(true);
  };

  const handleCloseArchiveChat = () => {
    setOpenArchiveChat(false);
  };

  const getUserNameById = (userId) => {
    const user = allUsers.find((user) => user.id === userId);

    return user.name;
  };

  const handleOpenChatByKanban = async () => {
    setKanban((prevKanban) => ({
      ...prevKanban,
      showKanbanSection: false,
    }));

    const response = await getRequest(`${baseUrl}/chats/find/${card.content.sender}/${card.content.fromMsg}`);

    if (response.error) {
      return setErrorMessage('Não foi possível abrir conversa! Entre em contato com o suporte');
    }

    setCurrentChat(response);
    fetchMessages(response, 1);
  };

  const handleArchiveChat = async () => {
    const numericCardId = stripPrefix(card.id);

    const payload = {
      status: 'arquivado',
    };

    const response = await patchRequest(`${baseUrl}/kanban/archiveChat/${Number(numericCardId)}`, JSON.stringify(payload));

    if (response.error) {
      return setErrorMessage('Erro ao arquivar chat! Entre em contato com o suporte');
    }

    setKanban((prev) => {
      const columnId = Object
        .keys(prev.currentKanban.columns)
        .find((colId) => prev.currentKanban.columns[colId].cardsIds.includes(card.id));

      if (!columnId) {
        return prev;
      }

      const updatedCardsIds = prev.currentKanban.columns[columnId].cardsIds.filter(
        (id) => id !== card.id,
      );

      const { [card.id]: removedCard, ...updatedCards } = prev.currentKanban.cards;

      return {
        ...prev,
        currentKanban: {
          ...prev.currentKanban,
          columns: {
            ...prev.currentKanban.columns,
            [columnId]: {
              ...prev.currentKanban.columns[columnId],
              cardsIds: updatedCardsIds,
            },
          },
          cards: updatedCards,
        },
      };
    });
  };

  const handleOpenScheduleMessage = async () => {
    setOpenScheduleMessage(true);
  };

  const handleExpandCardInformations = () => {
    setDialogOpen(true);
  };

  const getLineColor = (priority) => {
    switch (priority) {
      case 'baixa':
        return theme.kanbanCardPriorityLow;
      case 'media':
        return theme.kanbanCardPriorityMedium;
      case 'alta':
        return theme.kanbanCardPriorityHigh;
      default:
        return 'transparent';
    }
  };

  const lineColor = getLineColor(card.priority);

  const avatarUrl = card.content.profilePic || 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/1024px-User-avatar.svg.png?20201213175635';

  const visibleTagLimit = 3;
  const visibleTags = card.content.tags.slice(0, visibleTagLimit);
  const hiddenTagCount = card.content.tags.length - visibleTags.length;

  const getDueDate = () => {
    if (!card.due_date) return null;

    const dueDate = dayjs(card.due_date);
    const today = dayjs();
    const isToday = dueDate.isSame(today, 'day');

    let message = '';
    let textColor = theme.primaryColor;

    if (isToday) {
      message = `Vence hoje às ${dueDate.format('HH:mm')}`;
    } else if (card.expired === 1) {
      message = `Venceu em ${dueDate.format('DD/MM/YYYY HH:mm')}`;
      textColor = 'red';
    } else {
      message = dueDate.format('DD/MM/YYYY HH:mm');
    }

    return { message, textColor };
  };

  const dueDateInfo = getDueDate();

  return (
    <CardContainer
      ref={drag}
      style={{
        opacity: isDragging ? 0.7 : 1,
      }}
    >
      <CardLeftLine color={lineColor} />
      <CardHeader>

        <NameAndPhoneWrapper>
          <AvatarImg src={avatarUrl} alt="Foto do usuário do chat" />
          <h2>
            {card.content.name}
          </h2>
          <span>
            {formatPhoneNumber(card.content.phone)}
          </span>
        </NameAndPhoneWrapper>
        <button
          aria-label="Expandir informações desse chat"
          type="button"
          onClick={handleExpandCardInformations}
        >
          <Tooltip
            placement="top"
            arrow
            title="Expandir informações"
          >
            <ExpandMoreIcon />
          </Tooltip>
        </button>
        <CardDetailsDialog
          open={isDialogOpen}
          onClose={() => setDialogOpen(false)}
          card={card}
          openChatFunc={handleOpenChatByKanban}
          archiveChatFunc={handleArchiveChat}
          openScheduleMessageFunc={handleOpenScheduleMessage}
        />
      </CardHeader>

      <CardMain>
        <Tooltip
          placement="top"
          arrow
          title="Comentário mais recente"
        >
          <p>
            {card
              .content
              .comments?.length > 0
        && (`${getUserNameById(card
          .content
          .comments[card.content.comments.length - 1]?.user_id)}: ${card
          .content
          .comments[card.content.comments.length - 1]?.text}`)}
          </p>
        </Tooltip>
        <CardTagSection>
          {visibleTags.map((tag) => (
            <Tooltip key={tag.id} placement="top" arrow title={`Tag: ${tag.tag_name}`}>
              <CardTag>{tag.tag_name}</CardTag>
            </Tooltip>
          ))}

          {hiddenTagCount > 0 && (
          <Tooltip placement="top" arrow title={`mais ${hiddenTagCount} tags`}>
            <CardTag>
              +
              {hiddenTagCount}
            </CardTag>
          </Tooltip>
          )}
        </CardTagSection>
        {dueDateInfo && (
        <DueDateSection>
          <div>
            <EventIcon />
            <span>Vencimento: </span>
          </div>
          <h5 style={{ color: dueDateInfo.textColor }}>{dueDateInfo.message}</h5>
        </DueDateSection>
        )}

      </CardMain>

      <CardFooter>
        <CardActionsWrapper>
          <button
            aria-label="Abrir conversa com esse chat"
            type="button"
            onClick={handleOpenChatByKanban}
          >
            <Tooltip
              placement="top"
              arrow
              title="Abrir conversa"
            >
              <ChatIcon />
            </Tooltip>
          </button>

          <button
            aria-label="Agendar mensagem"
            type="button"
            onClick={handleOpenScheduleMessage}
          >
            <Tooltip
              placement="top"
              arrow
              title="Agendar mensagem"
            >
              <ScheduleSendIcon />
            </Tooltip>
          </button>

          <button
            aria-label="Arquivar conversa"
            type="button"
            onClick={handleOpenArchiveChat}
          >
            <Tooltip
              placement="top"
              arrow
              title="Arquivar conversa"
            >
              <ArchiveIcon />
            </Tooltip>
          </button>
        </CardActionsWrapper>
        <span>
          {moment(card.updatedAt).format('DD/MM/YYYY')}
        </span>
      </CardFooter>
      <CustomDialog
        open={openArchiveChat}
        onClose={handleCloseArchiveChat}
        onConfirm={handleArchiveChat}
        message="Tem certeza que deseja arquivar"
        contactName="o chat?"
        actionName="Arquivar"
        confirmButtonColor={theme.secondaryColor}
      />
      <ScheduleMessageDialog
        openScheduleMessage={openScheduleMessage}
        handleCloseScheduleMessage={() => setOpenScheduleMessage(false)}
        sender={card.content.sender}
        fromMsg={card.content.fromMsg}
      />
    </CardContainer>
  );
}

KanbanCard.propTypes = {
  card: PropTypes.shape({
    id: PropTypes.string.isRequired,
    due_date: PropTypes.string,
    expired: PropTypes.number,
    content: PropTypes.shape({
      comments: PropTypes.shape({
        createdAt: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      }),
      name: PropTypes.string.isRequired,
      phone: PropTypes.string.isRequired,
      profilePic: PropTypes.string,
    }).isRequired,
  }).isRequired,
  columnId: PropTypes.string.isRequired,
};

/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';

import {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import ChatIcon from '@mui/icons-material/Chat';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import AddIcon from '@mui/icons-material/Add';
import {
  CircularProgress,
  InputAdornment,
  Snackbar,
  Alert,
  TextField,
  Tooltip,
  Popover,
} from '@mui/material';

import InfiniteScroll from 'react-infinite-scroll-component';
import useWindowSize from '../../hooks/useWindowSize';
import {
  Container,
  StyledPopover,
  StyledButton,
  UserCheckbox,
  GroupDetails,
} from './styles';
import NewChat from '../NewChat';
import CloseAllTicketsButton from '../CloseAllTicketsButton';
import CustomAlert from '../CustomAlert';
import SidebarChat from '../SidebarChat';
import SidebarSections from '../SidebarSections';
import PopOverMenu from '../PopOverMenu';
import UserMenu from '../UserMenu';

import {
  baseUrl,
  getRequest,
  patchRequest,
  postRequest,
} from '../../utils/services';

import { AuthContext } from '../../context/AuthContext';
import { ChatContext } from '../../context/ChatContext';
import EditProfile from '../EditProfile';
import { useErrors } from '../../context/ErrorContext';
import InternalSidebarChat from '../SidebarChat/InternalSidebarChat';

export default function Sidebar({ profileImageURL = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBw0DncgKmYXYHN1DqEOnRqwMW7wNO9g3Chg&s' }) {
  const [chatPage, setChatPage] = useState(1);
  const [searchChats, setSearchChats] = useState('');
  const [filteredChats, setFilteredChats] = useState([]);
  const [hasMoreChats, setHasMoreChats] = useState(true);
  const [isFetchingChats, setIsFetchingChats] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isInternalGroupChat, setIsInternalGroupChat] = useState(false);
  const [selectedUsersForInternalChat, setSelectedUsersForInternalChat] = useState([]);
  const [internalGroupName, setInternalGroupName] = useState('');
  const [internalGroupDescription, setInternalGroupDescription] = useState('');

  const { user } = useContext(AuthContext);

  const { width } = useWindowSize();

  const isNotDesktop = width <= 834;
  const isNotFullHD = width <= 1370;

  const {
    allUsers,
    userChats,
    showNewChat,
    setShowNewChat,
    initialParamsForNewChat,
    isUserChatsLoading,
    setIsUserChatsLoading,
    setCurrentChat,
    fetchMessages,
    sortedChats,
    setSortedChats,
    userChatsQueue,
    openTickets,
    internalChats,
    getOpenTickets,
    isTicketTransferred,
    setIsTicketTransferred,
    activeSectionChats,
    setActiveSectionChats,
    handleCreateTicketClosedNotification,
    currentChat,
    getUserChats,
    isMessagesLoading,
    isEditingProfile,
    isEditingProfileChat,
    sortUserChatsByRecentMessages,
    alert,
    hideAlert,
    showAlert,
    kanban,
    setKanban,
  } = useContext(ChatContext);

  const { setErrorMessage } = useErrors();

  const containerRef = useRef();

  const { height } = useWindowSize();

  const openNewChatPopover = Boolean(anchorEl);
  const openNewChatButtonId = openNewChatPopover
    ? 'abrir chats para iniciar novo chat interno'
    : undefined;

  const { showKanbanSection } = kanban;

  const scrollToTop = () => {
    const container = containerRef.current;
    if (container) {
      containerRef.current.scrollTo({ top: 0 });
    }
  };

  const handleOpenNewInternalChatPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseNewInternalChatPopover = () => {
    setAnchorEl(null);
    setSelectedUsersForInternalChat([]);
  };

  const handleClosePopOver = () => {
    setShowNewChat(false);
  };

  const handleShowKanbanSection = async () => {
    setCurrentChat(null);

    const response = await getRequest(`${baseUrl}/kanban/${user.ambiente}/${user.id}`);

    if (response.error) {
      return setErrorMessage('Erro ao carregar kanbans! Entre em contato com o suporte.');
    }

    setKanban({
      ...kanban,
      kanbanBoards: response,
      showKanbanSection: true,
    });
  };

  const handleChatClick = async (chat) => {
    if ((currentChat?.fromMsg === chat.fromMsg
      && currentChat?.sender === chat.sender)
      || isMessagesLoading) {
      return;
    }

    const kanbanInformations = await getRequest(`${baseUrl}/kanban/kanbanChatInformations/${chat.sender}/${chat.fromMsg}`);

    setCurrentChat(chat);

    setKanban((prevKanban) => ({
      ...prevKanban,
      showKanbanSection: false,
      currentChatKanbans: kanbanInformations,
    }));

    fetchMessages(chat, 1);

    if (kanbanInformations.error) {
      return setErrorMessage('Não foi possível carregar as informações do chat relacionadas a Kanban. Entre em contato com o suporte!');
    }
  };

  const handleInternalChatClick = async (chat) => {
    setCurrentChat(chat);
  };

  const filterChats = async (searchQuery) => {
    try {
      setIsUserChatsLoading(true);

      const response = await getRequest(`${baseUrl}/chats/${user?.id}?page=${chatPage}&limit=15&searchQuery=${encodeURIComponent(searchQuery)}`);

      setIsUserChatsLoading(false);

      return response;
    } catch (error) {
      console.error('Error fetching chats from database: ', error);
      return [];
    }
  };

  const handleCloseAllTicketsInChats = async () => {
    const userId = user.id;

    openTickets.map((ticket) => {
      handleCreateTicketClosedNotification(
        ticket.sender,
        ticket.fromMsg,
        user.ambiente,
      );
    });

    const response = await patchRequest(`${baseUrl}/chats/closeAllTicketsInChats`, JSON.stringify({ userId }));

    if (response.error) {
      return console.error('Erro ao fechar todos os chamados: ', response.error);
    }

    showAlert('Chamados encerrados com sucesso', 'success', 5000);

    getOpenTickets();
  };

  function debounce(func, wait) {
    let timeout;
    const debouncedFunction = (...args) => {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
    debouncedFunction.cancel = () => clearTimeout(timeout);
    return debouncedFunction;
  }

  const DEBOUNCE_DELAY = 300;

  const handleSearch = async () => {
    if (searchChats !== ''
      && activeSectionChats === 'Geral'
    ) {
      scrollToTop();
      setChatPage(1);
      const results = await filterChats(searchChats);
      return setFilteredChats(results);
    }
    return setFilteredChats(userChats);
  };

  const debouncedHandleSearch = useCallback(debounce(handleSearch, DEBOUNCE_DELAY), [
    searchChats,
    activeSectionChats,
    userChats,
  ]);

  useEffect(() => {
    debouncedHandleSearch();

    return () => {
      debouncedHandleSearch.cancel();
    };
  }, [searchChats,
    activeSectionChats,
    userChats,
    debouncedHandleSearch]);

  useEffect(() => {
    const sorted = sortUserChatsByRecentMessages(filteredChats);
    setSortedChats(sorted);
  }, [
    filteredChats,
  ]);

  const fetchMoreChats = async () => {
    if (isFetchingChats) return;
    setIsFetchingChats(true);
    const response = await getUserChats(chatPage + 1);
    setChatPage(chatPage + 1);

    if (response.length < 15) {
      setHasMoreChats(false);
    }
    setIsFetchingChats(false);
  };

  const handleSectionClick = async (section) => {
    setActiveSectionChats(section);
  };

  const handleInputSearchChange = (event) => {
    setSearchChats(event.target.value);
  };

  const handleCloseSnackbar = (e, reason) => {
    if (reason === 'clickaway') return;

    setIsTicketTransferred(false);
  };

  const createInternalChat = async (userId) => {
    if (isInternalGroupChat) {
      const newGroupChat = {
        groupName: internalGroupName,
        groupDescription: internalGroupDescription,
        recipientUsersIds: selectedUsersForInternalChat,
        userId: user.id,
      };

      const response = await postRequest(`${baseUrl}/chats/createInternalGroupChat/${user.ambiente}`, JSON.stringify(newGroupChat));

      if (response.error) {
        return setErrorMessage('Não foi possível criar chat interno em grupo. Entre em contato com o suporte!');
      }

      setInternalGroupName('');
      setInternalGroupDescription('');
      setSelectedUsersForInternalChat([]);
      setIsInternalGroupChat(false);
    } else {
      const response = await postRequest(`${baseUrl}/chats/createInternalChat/${user.ambiente}`, JSON.stringify({
        userId: user.id,
        recipientUserId: userId,
      }));

      if (response.error) {
        return setErrorMessage('Não foi possível criar chat interno. Entre em contato com o suporte!');
      }
    }
    handleCloseNewInternalChatPopover();
  };

  const toggleInternalGroupChat = () => {
    setIsInternalGroupChat((prev) => !prev);
    setSelectedUsersForInternalChat([]);
  };

  const handleSelectUserForInternalChat = (userId) => {
    if (selectedUsersForInternalChat.includes(userId)) {
      setSelectedUsersForInternalChat(selectedUsersForInternalChat.filter((id) => id !== userId));
    } else {
      setSelectedUsersForInternalChat([...selectedUsersForInternalChat, userId]);
    }
  };

  if (isEditingProfile) return <EditProfile />;

  return ((!isNotDesktop && currentChat) || !currentChat) && (
    <Container style={{
      minWidth: showKanbanSection && isNotFullHD ? '350px' : '',
    }}
    >
      <header>
        <img
          src={profileImageURL}
          alt="Foto de perfil do usuário"
        />
        <Tooltip
          title={user.name}
          arrow
        >
          <h1>{user.name}</h1>
        </Tooltip>
        <div
          className="sidebar-header-icons"
        >

          <Tooltip
            arrow
            title="Kanban"
          >
            <button
              className="show-kanban-button"
              aria-label="quadros Kanban"
              type="button"
              onClick={handleShowKanbanSection}
            >
              <ViewKanbanIcon style={{
                color: '#919191',
              }}
              />
            </button>
          </Tooltip>

          <PopOverMenu
            tooltipTitle="Iniciar nova conversa"
            icon={(
              <ChatIcon
                style={{ color: '#919191' }}
              />
            )}
            openPopover={showNewChat}
            onClosePopover={handleClosePopOver}
          >
            <NewChat
              initialParams={initialParamsForNewChat}
              onCloseNewChat={handleClosePopOver}
            />
          </PopOverMenu>

          <PopOverMenu
            tooltipTitle="Opções do usuário"
            icon={(
              <MoreVertIcon
                style={{ color: '#919191' }}
              />
)}
          >
            <UserMenu />
          </PopOverMenu>

        </div>
      </header>
      <TextField
        placeholder="Procure uma conversa"
        variant="outlined"
        value={searchChats}
        sx={{
          '& fieldset': { border: 'none' },
          backgroundColor: '#F5F5F5',
          borderRadius: '10px',
          width: '95%',
          display: 'flex',
          justifyContent: 'flex-start',
          alignSelf: 'center',
          margin: '10px 0 10px 10px',

          '& .MuiInputBase-input': {
            fontSize: '16px',
            fontFamily: 'Montserrat, sans-serif',
            fontWeight: '500',
          },
        }}
        InputProps={{
          onChange: handleInputSearchChange,
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon fontSize="medium" />
            </InputAdornment>
          ),
        }}
      />

      <SidebarSections
        activeSection={activeSectionChats}
        onSectionClick={handleSectionClick}
        openedTickets={openTickets?.length}
        userChatQueue={userChatsQueue?.length}
      />

      <div style={{
        alignSelf: 'center',
      }}
      />
      <main
        id="scrollable-main"
        ref={containerRef}
      >
        {activeSectionChats === 'Interno' && (
          <div className="internal-chat-section">
            {internalChats?.length < 1
              ? (
                isUserChatsLoading ? (
                  <div
                    style={{
                      height: '80vh',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <CircularProgress
                      variant="indeterminate"
                    />
                  </div>
                )
                  : (
                    <h3
                      style={{
                        color: '#919191',
                        fontWeight: '600',
                        marginTop: '100px',
                        textAlign: 'center',
                      }}
                    >
                      Nenhum chat interno encontrado
                    </h3>
                  )
              )
              : (
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
                >
                  {internalChats?.filter(
                    (chat) => chat.memberName.toLowerCase().includes(searchChats.toLowerCase()),
                  )
                    .map((chat, index) => (
                      <div
                        key={index}
                        onClick={() => {
                          handleInternalChatClick(chat);
                        }}
                      >
                        <InternalSidebarChat
                          chat={chat}
                          isChatActive={false}
                        />
                      </div>
                    ))}
                </div>
              )}

            <div
              style={{
                marginTop: 'auto',
                padding: '10px',
                position: 'absolute',
                right: '10%',
                bottom: height < 768 ? '14%' : '2%',
              }}
            >

              <button
                aria-label="Iniciar novo chat"
                aria-describedby={openNewChatButtonId}
                type="button"
                className="new-chat-button"
                onClick={handleOpenNewInternalChatPopover}
              >
                <AddIcon />

              </button>
              <StyledPopover
                id="new-chat-popover"
                open={openNewChatPopover}
                onClose={handleCloseNewInternalChatPopover}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
              >
                <div style={{
                  margin: '10px',
                }}
                >
                  <h4>Inicie uma nova conversa interna</h4>

                  <div className="chat-mode-toggle">
                    <label htmlFor={`user-${user.id}`}>
                      <input
                        id={`user-${user.id}`}
                        type="checkbox"
                        checked={isInternalGroupChat}
                        onChange={toggleInternalGroupChat}
                      />
                      Criar conversa em grupo
                    </label>
                  </div>

                  {isInternalGroupChat ? (
                    <>
                      <div className="user-list">
                        <GroupDetails>
                          <label htmlFor="group-name">
                            Nome do Grupo
                            <input
                              id="group-name"
                              type="text"
                              value={internalGroupName}
                              onChange={(e) => setInternalGroupName(e.target.value)}
                              placeholder="Digite o nome do grupo"
                            />
                          </label>

                          <label htmlFor="group-description">
                            Descrição (Opcional)
                            <textarea
                              id="group-description"
                              value={internalGroupDescription}
                              onChange={(e) => setInternalGroupDescription(e.target.value)}
                              placeholder="Digite uma descrição breve"
                            />
                          </label>
                        </GroupDetails>
                        {allUsers
                          .filter((u) => u.id !== user.id)
                          .map((user) => (
                            <UserCheckbox key={user.id}>
                              <label
                                htmlFor={`user-${user.id}`}
                              >
                                <input
                                  id={`user-${user.id}`}
                                  type="checkbox"
                                  checked={selectedUsersForInternalChat.includes(user.id)}
                                  onChange={() => handleSelectUserForInternalChat(user.id)}
                                />
                                {user.name}
                              </label>
                            </UserCheckbox>
                          ))}
                      </div>
                      <div className="create-group-chat">
                        <StyledButton
                          onClick={createInternalChat}
                          disabled={selectedUsersForInternalChat.length === 0
                            || internalGroupName === ''}
                        >
                          Criar conversa em grupo
                        </StyledButton>
                      </div>
                    </>
                  ) : (
                    <div className="user-list">
                      {allUsers
                        .filter((u) => u.id !== user.id)
                        .map((user) => (
                          <button
                            className="select-user-button"
                            key={user.id}
                            type="button"
                            onClick={() => createInternalChat(user.id)}
                            aria-label={`Criar chat com ${user.name}`}
                          >
                            {user.name}
                          </button>
                        ))}
                    </div>
                  )}
                </div>
              </StyledPopover>
            </div>
          </div>
        )}

        {activeSectionChats === 'Meus' && (
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
        >

          {openTickets?.length < 1
            ? (
              isUserChatsLoading ? (
                <div
                  style={{
                    height: '80vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <CircularProgress
                    variant="indeterminate"
                  />
                </div>
              )
                : (
                  <h3
                    style={{
                      color: '#919191',
                      fontWeight: '600',
                      marginTop: '100px',
                      textAlign: 'center',
                    }}
                  >
                    Não existem chamados em aberto
                  </h3>
                )
            )
            : (
              <div>
                {openTickets?.filter((chat) => chat
                  .fromMsg
                  .toLowerCase()
                  .includes(searchChats
                    .toLowerCase()))
                  .map((chat, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        handleChatClick(chat);
                      }}
                    >
                      <SidebarChat
                        imageURL={openTickets ? openTickets.imageUrl : null}
                        chat={chat}
                        loggedUser={user}
                        isChatActive={
                        currentChat?.fromMsg === chat.fromMsg
                        && currentChat?.sender === chat.sender
                        }
                      />
                    </div>
                  ))}
                <div
                  style={{
                    marginTop: 'auto',
                    padding: '10px',
                    position: 'absolute',
                    right: '10%',
                    bottom: height < 768 ? '14%' : '2%',
                  }}
                >
                  <CloseAllTicketsButton
                    icon={<PhoneDisabledIcon />}
                    tooltip="Encerrar todos chamados"
                    closeAllTicketsFunc={handleCloseAllTicketsInChats}
                  />
                </div>
              </div>
            )}
        </div>
        )}

        <Snackbar
          open={isTicketTransferred}
          autoHideDuration={5000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity="success"
            variant="filled"
            sx={{ width: '100%' }}
          >
            Chamado transferido com sucesso
          </Alert>
        </Snackbar>

        <CustomAlert alert={alert} hideAlert={hideAlert} />

        {activeSectionChats === 'Aguardando' && (
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
        >

          {userChatsQueue?.length < 1
            ? (
              isUserChatsLoading ? (
                <div
                  style={{
                    height: '80vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <CircularProgress
                    variant="indeterminate"
                  />
                </div>
              )
                : (
                  <h3
                    style={{
                      color: '#919191',
                      fontWeight: '600',
                      marginTop: '100px',
                      textAlign: 'center',
                    }}
                  >
                    Não existem chamados na fila
                  </h3>
                )
            )
            : (
              <div>
                {userChatsQueue?.filter(
                  (chat) => chat.fromMsg.toLowerCase().includes(searchChats.toLowerCase()),
                )
                  .map((chat, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        handleChatClick(chat);
                      }}
                    >
                      <SidebarChat
                        imageURL={
                    userChatsQueue
                      ? userChatsQueue.imageUrl
                      : null
                  }
                        chat={chat}
                        loggedUser={user}
                        isChatActive={
                    currentChat?.fromMsg === chat.fromMsg
                    && currentChat?.sender === chat.sender
                  }
                      />
                    </div>
                  ))}
              </div>
            )}
        </div>
        )}

        {activeSectionChats === 'Geral' && (
        <InfiniteScroll
          dataLength={sortedChats?.length}
          next={fetchMoreChats}
          hasMore={hasMoreChats}
          scrollableTarget="scrollable-main"
          loader={(
            <div style={{
              height: '80vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            >
              <CircularProgress variant="indeterminate" />
            </div>
)}
        >

          {sortedChats?.length < 1
            ? (
              isUserChatsLoading ? (
                <div
                  style={{
                    height: '80vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <CircularProgress
                    variant="indeterminate"
                  />
                </div>
              )
                : (
                  <h3
                    style={{
                      color: '#919191',
                      fontWeight: '600',
                      marginTop: '100px',
                      textAlign: 'center',
                    }}
                  >
                    Nenhum resultado encontrado
                  </h3>
                )
            )
            : (
              sortedChats?.map((chat, index) => (
                <div
                  key={index}
                  onClick={() => {
                    handleChatClick(chat);
                  }}
                >
                  <SidebarChat
                    imageURL={
                    userChats
                      ? userChats.imageUrl
                      : null
                    }
                    chat={chat}
                    loggedUser={user}
                    isChatActive={
                      currentChat?.fromMsg === chat.fromMsg
                        && currentChat?.sender === chat.sender
                    }
                  />
                </div>
              ))

            )}
        </InfiniteScroll>
        )}

      </main>
    </Container>
  );
}

Sidebar.propTypes = {
  profileImageURL: PropTypes.string,
};

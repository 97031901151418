/* eslint-disable no-nested-ternary */
import {
  useState,
  useContext,
  useRef,
  useEffect,
} from 'react';

import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import QuickreplyOutlinedIcon from '@mui/icons-material/QuickreplyOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import MicOutlinedIcon from '@mui/icons-material/MicOutlined';

import { Tooltip } from '@mui/material';
import EmojiPicker from 'emoji-picker-react';
import moment from 'moment';
import 'moment/locale/pt-br';
import he from 'he';

import InfiniteScroll from 'react-infinite-scroll-component';

import {
  baseUrl,
  formatPhoneNumber,
  postRequest,
  deleteRequest,
  getRequest,
  patchRequest,
} from '../../utils/services';

import ChatHeader from '../ChatHeader';
import ChatMessage from '../ChatMessage';
import KanbanBoards from '../KanbanBoards';

import { AuthContext } from '../../context/AuthContext';
import { ChatContext } from '../../context/ChatContext';

import {
  Container,
  ContainerHeader,
  ContainerChat,
  ContainerFooter,
  QuickAnswerContainer,
  NewQuickAnswerInput,
} from './styles';

import ChatIntro from '../ChatIntro';
import SendMedias from '../SendMedias';
import CustomDialog from '../CustomDialog';
import PopOverMenu from '../PopOverMenu';
import AudioRecorder from '../AudioRecorder';
import useWindowSize from '../../hooks/useWindowSize';
import { useErrors } from '../../context/ErrorContext';

export default function ChatWindow() {
  const {
    socket,
    allUsers,
    setMessagesPage,
    hasMoreMessages,
    urlImageToSend,
    quickAnswers,
    setQuickAnswers,
    setMessageToScroll,
    currentChat,
    containerRef,
    handleScrollChatToBottom,
    isInitialMount,
    messages,
    isMessagesLoading,
    sendMessage,
    setAudioBlobURL,
    selectedImageToSend,
    setUrlImageToSend,
    urlVideoToSend,
    setUrlVideoToSend,
    setCurrentChat,
    fetchMessages,
    messagesPage,
    selectedVideoToSend,
    setSelectedVideoToSend,
    setSelectedImageToSend,
    selectedDocumentToSend,
    urlDocumentToSend,
    setUrlDocumentToSend,
    setSelectedDocumentToSend,
    updateUserChatFromQueueToChats,
    fetchMediaUrls,
    audioBlobURL,
    isRecordingAudio,
    isUploadingAudioMessage,
    cancelAudioRecording,
    isRecipientOnline,
    setOpenTransferModal,
    kanban,
  } = useContext(ChatContext);

  moment.locale('pt-br');

  const [isEmojiMenuVisible, setIsEmojiMenuVisible] = useState(false);
  const [isChatFullyBottom, setIsChatFullyBottom] = useState(false);
  const [textMessage, setTextMessage] = useState('');
  const [isAComment, setIsAComment] = useState(false);
  const [newQuickAnswer, setNewQuickAnswer] = useState({
    title: '',
    answer: '',
  });

  const { setErrorMessage } = useErrors();

  const {
    showQuickAnswers,
    isAddingNewAnswer,
    quickAnswersData,
    messageIdToQuickAnswer,
    messagesWithMediaUrl,
  } = quickAnswers;

  const { showKanbanSection } = kanban;

  const { user } = useContext(AuthContext);

  const { width, height } = useWindowSize();

  const isNotDesktop = width <= 1028;

  const prevChatRef = useRef(currentChat);

  const container = containerRef.current;

  const toggleIsAComment = () => {
    setIsAComment((prevState) => !prevState);
  };

  const handleMediaByType = async (mediaType, selectedQuickAnswer) => {
    switch (true) {
      case mediaType.startsWith('audio'):
        return setAudioBlobURL(selectedQuickAnswer.mediaUrl);

      case mediaType.startsWith('image'):
        return setUrlImageToSend([selectedQuickAnswer.mediaUrl]);

      case mediaType.startsWith('video'):
        return setUrlVideoToSend([selectedQuickAnswer.mediaUrl]);

      case mediaType.startsWith('application') || mediaType.startsWith('document') || mediaType.startsWith('text'):
        setSelectedDocumentToSend([{
          type: mediaType,
        }]);
        return setUrlDocumentToSend([selectedQuickAnswer.mediaUrl]);

      default:
        console.log('Unknown media type');
        return null;
    }
  };

  const handleSelectQuickAnswer = async (index) => {
    const selectedQuickAnswer = { ...quickAnswersData[index] };

    if (selectedQuickAnswer.mediaType) {
      const selectedQuickAnswerMedia = await fetchMediaUrls({ ...selectedQuickAnswer });

      await handleMediaByType(selectedQuickAnswer
        .mediaType, selectedQuickAnswerMedia);
    }

    setTextMessage(selectedQuickAnswer.body);
  };

  const handleClearQuickAnswerByIndex = async (index) => {
    const [quickAnswerSelectedToDelete] = quickAnswersData.filter((answer, i) => i === index);

    const response = await deleteRequest(`${baseUrl}/messages/deleteQuickAnswerById/${quickAnswerSelectedToDelete.id}`);

    if (response?.error) {
      return setErrorMessage('Erro ao limpar resposta rápida. Entre em contato com o suporte');
    }

    setQuickAnswers(
      {
        ...quickAnswers,
        quickAnswersData: quickAnswersData.filter((answer, i) => i !== index),
      },
    );
  };

  const handleClearAllQuickAnswers = async () => {
    const response = await deleteRequest(`${baseUrl}/messages/deleteQuickAnswersByUser/${user.id}`);

    if (response?.error) {
      return setErrorMessage('Erro ao limpar todas respostas rápidas. Entre em contato com o suporte');
    }

    setQuickAnswers({
      ...quickAnswers,
      quickAnswersData: [],
    });
  };

  const handleIsAddingNewAnswer = () => {
    setQuickAnswers({
      ...quickAnswers,
      isAddingNewAnswer: true,
    });
  };

  const handleBackToMainQuickAnswers = () => {
    setQuickAnswers({
      ...quickAnswers,
      isAddingNewAnswer: false,
    });

    setNewQuickAnswer({
      title: '',
      answer: '',
    });
  };

  const handleNewQuickAnswerTitleChange = (e) => {
    const { value } = e.target;

    setNewQuickAnswer({
      ...newQuickAnswer,
      title: value,
    });
  };

  const handleOnNewQuickAnswerChange = (e) => {
    const { value } = e.target;

    setNewQuickAnswer({
      ...newQuickAnswer,
      answer: value,
    });
  };

  const handleOnConfirmQuickAnswerViaPopover = async () => {
    const encodedBody = he.encode(newQuickAnswer.answer, {
      useNamedReferences: true,
      decimal: true,
      allowUnsafeSymbols: true,
    });

    const payload = {
      id_usuario: user.id,
      titleMsg: newQuickAnswer.title,
      body: encodedBody,
      mediaType: null,
      mediaUrl: null,
      ambiente: user.ambiente,
    };

    const response = await postRequest(`${baseUrl}/messages/createQuickAnswer/${user.id}`, JSON.stringify(payload));

    if (response.error) {
      return setErrorMessage(response.error);
    }

    setQuickAnswers({
      ...quickAnswers,
      isAddingNewAnswer: false,
      quickAnswersData: [...quickAnswersData, {
        id: response,
        id_usuario: user.id,
        createdAt: Date.now(),
        titleMsg: newQuickAnswer.title,
        body: newQuickAnswer.answer,
        mediaType: null,
        mediaUrl: null,
      }],
    });

    setNewQuickAnswer({
      title: '',
      answer: '',
    });
  };

  const handleOnConfirmQuickAnswerViaMsg = async () => {
    const removeAttendantName = (body) => {
      const regex = /^\*[^*]+\*:\s*/;

      const modifiedBody = body.replace(regex, '');

      return modifiedBody;
    };

    const messageToTurnIntoQuickAnswer = messagesWithMediaUrl.find((msg) => {
      return msg.idMsg === messageIdToQuickAnswer;
    });

    if (!messageToTurnIntoQuickAnswer) return;

    const body = removeAttendantName(messageToTurnIntoQuickAnswer.body);

    let quickAnswerPayload = {};

    if (messageToTurnIntoQuickAnswer.mediaType || messageToTurnIntoQuickAnswer.mediaUrl) {
      quickAnswerPayload = await getRequest(`${baseUrl}/messages/${messageToTurnIntoQuickAnswer.idMsg}`);

      if (quickAnswerPayload.error) {
        return setErrorMessage('Erro ao salvar resposta rápida, entre em contato com o suporte.');
      }
    }
    const mediaType = quickAnswerPayload.mediaType || null;
    const mediaUrl = quickAnswerPayload.mediaUrl || null;

    const payload = {
      id_usuario: user.id,
      titleMsg: newQuickAnswer.title,
      body,
      mediaType,
      mediaUrl,
      ambiente: user.ambiente,
    };

    const response = await postRequest(`${baseUrl}/messages/createQuickAnswer/${user.id}`, JSON.stringify(payload));

    if (response.error) {
      return setErrorMessage(response.error);
    }

    setQuickAnswers({
      ...quickAnswers,
      messageIdToQuickAnswer: '',
      showQuickAnswers: false,
      isAddingNewAnswer: false,
      quickAnswersData: [...quickAnswersData, {
        id: response,
        id_usuario: user.id,
        createdAt: Date.now(),
        titleMsg: newQuickAnswer.title,
        body,
        mediaType,
        mediaUrl,
      }],
    });

    setNewQuickAnswer({
      title: '',
      answer: '',
    });
  };

  const handleCloseQuickAnswer = () => {
    setQuickAnswers({
      ...quickAnswers,
      showQuickAnswers: false,
    });
  };

  const getMediaTypeIcon = (mediaType) => {
    if (!mediaType) return null;

    switch (true) {
      case mediaType.startsWith('image'):
        return <ImageOutlinedIcon style={{ fontSize: '20px', marginLeft: '5px' }} />;

      case mediaType.startsWith('video'):
        return <VideocamOutlinedIcon style={{ fontSize: '20px', marginLeft: '5px' }} />;

      case mediaType.startsWith('audio'):
        return <MicOutlinedIcon style={{ fontSize: '20px', marginLeft: '5px' }} />;

      case mediaType.startsWith('document'):
      case mediaType.startsWith('text'):
      case mediaType.startsWith('application'):
        return <InsertDriveFileOutlinedIcon style={{ fontSize: '20px', marginLeft: '5px' }} />;

      default:
        return null;
    }
  };

  const handleScroll = () => {
    if (container) {
      const {
        scrollTop,
        scrollHeight,
        clientHeight,
      } = container;

      setIsChatFullyBottom(scrollTop + clientHeight === scrollHeight);

      if (!isMessagesLoading && scrollTop < 20 && hasMoreMessages) {
        const prevScrollHeight = container.scrollHeight / 3;
        setMessagesPage((prevPage) => prevPage + 1);
        container.scrollTop += prevScrollHeight;
      }
    }
  };

  useEffect(() => {
    if ((isInitialMount?.current
      && messages?.length > 0)
    ) {
      handleScrollChatToBottom();
      isInitialMount.current = false;
    }

    if (!currentChat || prevChatRef.current !== currentChat) {
      isInitialMount.current = true;
      prevChatRef.current = currentChat;
    }
  }, [
    handleScroll,
    messages,
    currentChat,
    prevChatRef,
    isInitialMount,
    isMessagesLoading,
    hasMoreMessages,
    setMessagesPage]);

  const textareaRef = useRef(null);
  const containerFooterRef = useRef(null);

  const handleResizeTextarea = () => {
    const textarea = textareaRef.current;

    if (textarea) {
      textarea.style.height = 'auto';
      const { scrollHeight } = textarea;
      const maxHeight = 150;

      if (scrollHeight > maxHeight) {
        textarea.style.height = `${maxHeight}px`;
        textarea.style.overflowY = 'auto';
      } else {
        textarea.style.height = `${scrollHeight}px`;
        textarea.style.overflowY = 'hidden';
      }
    }
  };

  const handlePaste = (event) => {
    const clipboardItem = event.clipboardData.items[0];

    if (clipboardItem && clipboardItem.type.startsWith('image/')) {
      const blob = clipboardItem.getAsFile();

      if (blob) {
        setSelectedImageToSend([blob]);
      }

      event.preventDefault();
    }
  };

  const handleDragOver = (e) => {
    const { dataTransfer } = e;

    e.preventDefault();
    dataTransfer.dropEffect = 'copy';
  };

  const handleDrop = (event) => {
    event.preventDefault();

    const files = Array.from(event.dataTransfer.files);
    if (files.length > 0) {
      const file = files[0];

      if (file.type.startsWith('image/')) {
        setSelectedImageToSend(files);
      } else if (file.type.startsWith('video/')) {
        setSelectedVideoToSend(files);
      } else {
        setSelectedDocumentToSend(files);
      }
    }
  };

  const toggleEmojiMenu = () => {
    setIsEmojiMenuVisible((prevState) => !prevState);
  };

  const handleEmojiClick = (emojiObject) => {
    setTextMessage((prevText) => prevText + emojiObject.emoji);
  };

  const handleOpenTransferModal = () => {
    setOpenTransferModal(true);
  };

  const handleChange = (event) => {
    setTextMessage(event.target.value);
  };

  const sendMediaMessages = async (textMessage, currentChat, setTextMessage, mediaUrls) => {
    if (mediaUrls.length > 0) {
      Promise.all(
        mediaUrls.map(async (url) => {
          await sendMessage(
            textMessage,
            currentChat?.fromMsg,
            setTextMessage,
            url,
          );
        }),
      );
    }
  };

  const handleSendMessage = async () => {
    if (
      (!textMessage.trim()
      || /^[\s\n]*$/.test(textMessage))
      && !urlImageToSend
      && !audioBlobURL
      && !urlVideoToSend
      && !urlDocumentToSend
    ) return;

    if (!isAComment) {
      updateUserChatFromQueueToChats(currentChat);
    }

    const textMessageWithName = `*${user.name}*:\n ${textMessage}`;

    if (isAComment) {
      const commentPayload = {
        sender: currentChat.sender,
        fromMsg: currentChat.fromMsg,
        ambiente: user.ambiente,
        body: textMessageWithName,
        ack: 0,
        mediaType: 'text/notification',
        fromMe: 3,
        isDeleted: null,
        setorId: currentChat.setor_id,
        userId: currentChat.usuario_id,
        createdAt: Date.now(),
      };

      const response = await postRequest(`${baseUrl}/chats/insertNotification`, JSON.stringify(commentPayload));

      if (response.error) {
        return setErrorMessage('Erro ao adicionar comentário! Entre em contato com o suporte.');
      }

      socket.emit('sendComment', commentPayload, user.ambiente);

      setTextMessage('');
      return setIsAComment(false);
    }

    const sendMedia = async (mediaUrl) => {
      if (mediaUrl?.length > 0) {
        await sendMediaMessages(textMessage, currentChat, setTextMessage, mediaUrl);
      }
    };

    await Promise.all([
      sendMedia(urlImageToSend),
      sendMedia(urlVideoToSend),
      sendMedia(urlDocumentToSend),
    ]);

    const isAnyMediaPresent = urlImageToSend.length > 0
    || urlVideoToSend.length > 0
    || urlDocumentToSend.length > 0;

    if (!isAnyMediaPresent) {
      await sendMessage(
        textMessage,
        currentChat?.fromMsg,
        setTextMessage,
        audioBlobURL || null,
      );
    }

    if (!currentChat.primeira_resposta_data) {
      const updateTicketResponse = await patchRequest(`${baseUrl}/chats/updateFirstAnswerDate`, JSON.stringify({
        ticketId: currentChat.id,
        userId: user.id,
      }));

      if (updateTicketResponse.error) {
        return setErrorMessage('Algo de errado aconteceu. Entre em contato com o suporte');
      }

      setCurrentChat((prevCurrentChat) => ({
        ...prevCurrentChat,
        primeira_resposta_data: Date.now(),
      }));
    }

    setUrlImageToSend([]);
    setSelectedImageToSend(null);
    setUrlVideoToSend([]);
    setSelectedVideoToSend(null);
    setUrlDocumentToSend([]);
    setSelectedDocumentToSend(null);
    setAudioBlobURL(null);
    cancelAudioRecording();
  };

  const handleSendMessageWithKeyDown = async (event) => {
    const { key, shiftKey, target } = event;

    if (key === 'Enter' && !shiftKey) {
      handleSendMessage();
      return event.preventDefault();
    }

    if (key === 'Enter' && shiftKey) {
      event.preventDefault();
      const cursorPosition = target.selectionStart;
      const newText = `${textMessage.slice(0, cursorPosition)}\n${textMessage.slice(cursorPosition)}`;
      setTextMessage(newText);

      setTimeout(() => {
        target.selectionStart = cursorPosition + 1;
        target.selectionEnd = cursorPosition + 1;
      }, 0);
    }
  };

  const ipbxNumber = user?.senders[currentChat?.sender]?.telefone?.toString();

  const leadOrigin = currentChat?.lead_origem;

  const currentChatAttendant = allUsers.find((u) => currentChat?.usuario_id === u.id);

  const contactName = currentChat?.customName
  || currentChat?.nome
  || formatPhoneNumber(currentChat?.fromMsg);

  if (showKanbanSection) return <KanbanBoards />;

  if (!currentChat && !isNotDesktop) return <ChatIntro />;

  return currentChat && (
    <Container
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <ContainerHeader>
        <ChatHeader
          contactName={contactName}
          isOnline={isRecipientOnline}
          ipbxNumber={formatPhoneNumber(ipbxNumber)}
          leadOrigin={leadOrigin}
          attendantName={currentChatAttendant?.name}
        />
      </ContainerHeader>

      {urlImageToSend?.length > 0
        ? (
          <SendMedias
            type="image"
            urlMediaTosend={urlImageToSend}
            setMediaToSendFunc={setUrlImageToSend}
            setSelectedMediaToSendFunc={setSelectedImageToSend}
          />
        )
        : urlVideoToSend?.length > 0
          ? (
            <SendMedias
              type="video"
              urlMediaTosend={urlVideoToSend}
              setMediaToSendFunc={setUrlVideoToSend}
              setSelectedMediaToSendFunc={setSelectedVideoToSend}
            />
          )
          : urlDocumentToSend?.length > 0
            ? (
              <SendMedias
                type="document"
                urlMediaTosend={urlDocumentToSend}
                setMediaToSendFunc={setUrlDocumentToSend}
                setSelectedMediaToSendFunc={setSelectedDocumentToSend}
              />
            ) : (
              <ContainerChat
                ref={containerRef}
                id="containerChatId"
              >
                <InfiniteScroll
                  scrollableTarget="containerChatId"
                  dataLength={messages.length}
                  next={() => fetchMessages(currentChat, messagesPage)}
                  hasMore={hasMoreMessages}
                  loader={(
                    <div style={{
                      height: '100vh',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    >
                      <CircularProgress variant="indeterminate" />
                    </div>
      )}
                  loaderStyle={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  inverse
                  onScroll={handleScroll}
                  key={`${currentChat?.id}-${messages.length}`}
                >
                  {messages?.filter((message) => {
                    const isUserDeletedChat = (
                      message.firstId
                  === user.id
                  && message.firstIdDeleted
                    )
              || (
                message.secondId
                === user.id
                && message.secondIdDeleted
              );

                    return !isUserDeletedChat;
                  })
                    .reverse()
                    .map((message, index) => (
                      <div
                        key={index}
                        id={`message-${index}`}
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                        ref={index === 0 ? setMessageToScroll : null}
                      >
                        <ChatMessage
                          body={message.body}
                          date={moment(message.createdAt).calendar()}
                          idMsg={message.idMsg}
                          senderId={message.sender}
                          mediaType={message.mediaType}
                          mediaUrl={message.mediaUrl}
                          videoThumb={message.videoThumb}
                          isDeleted={message.isDeleted}
                          isRecipientOnline={isRecipientOnline}
                          reacted={message.reacted}
                          isReaded={message.ack >= 3}
                          isReceived={message.ack >= 2}
                          fromMe={message.fromMe}
                          isAComment={message.fromMe}
                          quotedMsgId={message.quotedMsgId}
                        />
                      </div>
                    ))}

                </InfiniteScroll>
              </ContainerChat>

            )}

      <ContainerFooter ref={containerFooterRef}>
        <EmojiPicker
          onEmojiClick={handleEmojiClick}
          height="400px"
          searchPlaceHolder="Procurar"
          open={isEmojiMenuVisible}
          style={{
            position: 'absolute',
            bottom: '45px',
            zIndex: '1',
          }}
        />

        <div className="messages-input-container">
          <button
            aria-label="Adicionar comentário"
            type="button"
            style={{
              marginLeft: '15px',
              paddingTop: '5px',
            }}
            onClick={toggleIsAComment}
          >
            {!audioBlobURL
            && !isRecordingAudio
            && !isUploadingAudioMessage
            && (
              isAComment ? (
                <Tooltip
                  arrow
                  title="Cancelar comentário"
                >
                  <CloseIcon style={{
                    color: '#919191',
                    marginRight: '10px',
                    fontSize: '27px',
                  }}
                  />
                </Tooltip>
              ) : (
                <Tooltip
                  arrow
                  title="Adicionar comentário"
                >
                  <TextsmsOutlinedIcon style={{
                    color: '#919191',
                    marginRight: '10px',
                    fontSize: '27px',
                  }}
                  />
                </Tooltip>
              )
            )}

          </button>
          {
            !audioBlobURL
            && !isRecordingAudio
            && !isUploadingAudioMessage
            && (currentChat?.usuario_id === user.id
              || !currentChat?.usuario_id
              || currentChat?.status === 3
              || isAComment
            )

              ? (
                <>
                  <PopOverMenu
                    openPopover={false}
                    tooltipTitle="Respostas rápidas"
                    icon={(
                      <QuickreplyOutlinedIcon
                        style={{ color: '#919191' }}
                      />
              )}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                  >
                    <QuickAnswerContainer>
                      {isAddingNewAnswer
                        ? (
                          <div className="add-new-quick-answer-div">
                            <button
                              type="button"
                              aria-label="Voltar para tela principal de mensagens rápidas"
                              className="back-to-main-quick-answers-button"
                              onClick={handleBackToMainQuickAnswers}
                            >
                              <ArrowBackIcon style={{ color: '#919191' }} />
                            </button>
                            <input
                              placeholder="Adicione um título"
                              type="text"
                              value={newQuickAnswer.title}
                              onChange={handleNewQuickAnswerTitleChange}
                            />
                            <textarea
                              value={newQuickAnswer.answer}
                              placeholder="Adicione a nova resposta rápida"
                              onChange={handleOnNewQuickAnswerChange}
                            />
                          </div>

                        )
                        : (
                          <>
                            <div className="quick-answers-header">
                              <h4>Respostas rápidas</h4>
                              <button
                                aria-label="Limpas todas"
                                type="button"
                                onClick={handleClearAllQuickAnswers}
                              >
                                Limpar todas
                              </button>
                            </div>

                            <div className="answers-wrapper">

                              {quickAnswersData.map((answer, index) => (
                                <button
                                  key={index}
                                  className="quick-answers-button"
                                  type="button"
                                  aria-label="Selecionar resposta rápida"
                                  onClick={() => handleSelectQuickAnswer(index)}
                                >
                                  <h3>
                                    {he.decode(answer.titleMsg)}
                                    {getMediaTypeIcon(answer.mediaType)}
                                  </h3>
                                  <p>
                                    {he.decode(answer.body)}
                                  </p>
                                  <button
                                    className="delete-quick-answer-button"
                                    type="button"
                                    aria-label="Excluir resposta rápida"
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      handleClearQuickAnswerByIndex(index);
                                    }}
                                  >
                                    x
                                  </button>
                                </button>
                              ))}
                            </div>
                          </>
                        )}

                      <Tooltip
                        arrow
                        title={
                            isAddingNewAnswer
                              ? 'Salvar resposta rápida'
                              : 'Adicionar nova resposta rápida'
                          }
                      >

                        <button
                          type="button"
                          aria-label={
                            isAddingNewAnswer
                              ? 'Salvar resposta rápida'
                              : 'Adicionar nova resposta rápida'
                          }
                          className="add-quick-answers-button"
                          disabled={(newQuickAnswer.title === ''
                            || newQuickAnswer.answer === '')
                            && isAddingNewAnswer}
                          onClick={isAddingNewAnswer
                            ? handleOnConfirmQuickAnswerViaPopover
                            : handleIsAddingNewAnswer}
                        >
                          {
                            isAddingNewAnswer
                              ? (
                                <SaveIcon style={{
                                  color: '#F5F5F5',
                                  fontSize: '30px',
                                }}
                                />
                              )
                              : (
                                <AddIcon style={{
                                  color: '#F5F5F5',
                                  fontSize: '30px',
                                }}
                                />
                              )
                          }

                        </button>
                      </Tooltip>
                    </QuickAnswerContainer>
                  </PopOverMenu>
                  <button
                    type="button"
                    className="emojis-button"
                    onClick={toggleEmojiMenu}
                  >
                    {isEmojiMenuVisible
                      ? (
                        <CloseIcon style={{ fontSize: '27px' }} />
                      )
                      : (
                        <InsertEmoticonIcon style={{ fontSize: '27px' }} />
                      )}
                  </button>
                  <textarea
                    ref={textareaRef}
                    placeholder={isAComment ? 'Digite um comentário' : 'Digite uma mensagem'}
                    value={textMessage}
                    onChange={(e) => {
                      handleChange(e);
                      handleResizeTextarea();
                    }}
                    onKeyDown={handleSendMessageWithKeyDown}
                    rows={1}
                    style={{
                      width: '100%',
                      resize: 'none',
                      overflowY: 'hidden',
                      lineHeight: '20px',
                      backgroundColor: isAComment ? '#FFFF66' : '#F5F5F5',
                    }}
                    onPaste={handlePaste}
                  />

                </>
              )
              : isRecordingAudio
              || audioBlobURL
              || isUploadingAudioMessage
                ? ''
                : (
                  <div className="no-input-container">
                    <h5>Chamado com outro atendente.</h5>
                    <button
                      type="button"
                      aria-label="Transferir chamado"
                      onClick={handleOpenTransferModal}
                    >
                      Transferir chamado
                    </button>
                  </div>
                )
        }

          {
            textMessage === ''
            && urlImageToSend.length === 0
            && !isAComment
            && urlVideoToSend.length === 0
            && !selectedDocumentToSend
            && (currentChat?.usuario_id === user.id
              || !currentChat?.usuario_id
              || currentChat?.status === 3)
            && (
              <AudioRecorder />
            )
          }

          {(textMessage !== ''
          || audioBlobURL
          || urlImageToSend.length > 0
          || urlVideoToSend.length > 0
          || selectedDocumentToSend
          || isAComment)
        && (
          <button
            type="button"
            className="send-message-button"
            onClick={handleSendMessage}
            disabled={isMessagesLoading}
            aria-label="Ícone de enviar mensagem"
            style={{
              zIndex: '999',
            }}
          >
            <SendIcon style={{
              color: '#F5F5F5',
              fontSize: '25px',
              marginLeft: '3px',
              marginBottom: '2px',
            }}
            />
          </button>
        )}
        </div>
        <CustomDialog
          open={showQuickAnswers}
          onClose={handleCloseQuickAnswer}
          onConfirm={handleOnConfirmQuickAnswerViaMsg}
          enableConfirmButton={newQuickAnswer.title !== ''}
          message="Adicione um título a resposta rápida: "
          actionName="Adicionar"
          confirmButtonColor="#014F6D"
        >
          <NewQuickAnswerInput
            value={newQuickAnswer.title}
            onChange={handleNewQuickAnswerTitleChange}
          />

        </CustomDialog>
      </ContainerFooter>
    </Container>
  );
}

import React, {
  useState,
  useEffect,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Container, FormSection, Input, Label, Button,
} from '../NewKanbanBoardStyles';
import { DeleteButton } from './styles';
import CustomAlert from '../../CustomAlert';
import ColumnForm from '../ColumnForm';
import useAlert from '../../../hooks/useAlert';
import { baseUrl, deleteRequest, postRequest } from '../../../utils/services';
import CustomDialog from '../../CustomDialog';
import { useErrors } from '../../../context/ErrorContext';
import { AuthContext } from '../../../context/AuthContext';
import { ChatContext } from '../../../context/ChatContext';

export default function KanbanBoardSettings({
  currentKanban,
  onSave,
  onClose,
  onRemoveColumn,
}) {
  const [boardName, setBoardName] = useState(currentKanban.name || '');
  const [boardDescription, setBoardDescription] = useState(currentKanban.description || '');
  const [columns, setColumns] = useState([]);
  const [columnOrder, setColumnOrder] = useState([]);
  const [showDeleteBoardDialog, setShowDeleteBoardDialog] = useState(false);
  const [columnToDeleteData, setColumnToDeleteData] = useState({
    columnToDelete: {},
    showDeleteDialog: false,
  });

  const { columnToDelete, showDeleteDialog } = columnToDeleteData;

  const { alert, showAlert, hideAlert } = useAlert();

  const theme = useTheme();

  const { setErrorMessage } = useErrors();

  const { user } = useContext(AuthContext);

  const { setKanban } = useContext(ChatContext);

  useEffect(() => {
    if (!currentKanban) return;

    setBoardName(currentKanban.name);
    setBoardDescription(currentKanban.description);
    setColumnOrder(currentKanban.columnOrder);
    setColumns(Object.values(currentKanban.columns || []));
  }, [currentKanban]);

  useEffect(() => {
    onSave({
      name: boardName,
      description: boardDescription,
      columns,
      columnOrder,
    });
  }, [boardName, boardDescription, columns]);

  const handleOpenDeleteColumnDialog = (index, column) => {
    setColumnToDeleteData({
      columnToDelete: {
        ...column,
        index,
      },
      showDeleteDialog: true,
    });
  };

  const handleShowDeleteBoardDialog = () => {
    setShowDeleteBoardDialog(true);
  };

  const handleCloseDeleteBoardDialog = () => {
    setShowDeleteBoardDialog(false);
  };

  const handleCloseDeleteColumnDialog = () => {
    setColumnToDeleteData((prev) => ({
      ...prev,
      showDeleteDialog: false,
    }));
  };

  const handleDeleteBoard = async () => {
    try {
      const response = await deleteRequest(`${baseUrl}/kanban/deleteBoard/${currentKanban.numericId}`);

      onClose(({ showKanbanSettings: false }));

      setKanban((prevKanban) => ({
        ...prevKanban,
        currentKanban: {},
        kanbanBoards: prevKanban
          .kanbanBoards
          .filter((board) => board.id !== currentKanban.numericId),
      }));

      showAlert('Quadro excluído com sucesso', 'success', 5000);
    } catch (error) {
      setErrorMessage('Não foi possível excluir o quadro! Entre em contato com o suporte.');
      console.error(error);
    }
  };

  const handleColumnChange = (index, field, value) => {
    if (field === 'position') {
      const newPosition = parseInt(value, 10);
      const isDuplicate = columns.some((col, idx) => col.position === newPosition && idx !== index);

      if (isDuplicate) {
        showAlert('Essa posição já foi selecionada em outra coluna. Selecione uma nova', 'info', 5000);
        return;
      }
    }

    setColumns((prevColumns) => {
      const updatedColumns = [...prevColumns];
      updatedColumns[index] = { ...updatedColumns[index], [field]: value };
      return updatedColumns;
    });
  };

  const addColumn = async () => {
    try {
      const newColumn = {
        title: '',
        position: columns.length + 1,
        user_id: user.id,
        board_id: currentKanban.numericId,
        cardIds: [],
      };

      const response = await postRequest(`${baseUrl}/kanban/createColumn`, JSON.stringify(newColumn));

      setColumns([...columns, {
        ...newColumn,
        id: `column-${response}`,
        numericId: response,
      }]);

      setColumnOrder((prevColumnOrder) => [...prevColumnOrder, `column-${response}`]);
    } catch (error) {
      onClose(({ showKanbanSettings: false }));
      console.error('Error during column addition:', error);
      setErrorMessage('Erro ao adicionar nova coluna. Entre em contato com o suporte.');
    }
  };

  const moveColumn = (fromIndex, toIndex) => {
    const updatedColumns = [...columns];
    const movedColumn = updatedColumns[fromIndex];
    updatedColumns.splice(fromIndex, 1);
    updatedColumns.splice(toIndex, 0, movedColumn);

    updatedColumns.forEach((column, index) => {
      // eslint-disable-next-line no-param-reassign
      column.position = index + 1;
    });

    setColumns(updatedColumns);

    const updatedOrder = [...columnOrder];
    updatedOrder.splice(fromIndex, 1);
    updatedOrder.splice(toIndex, 0, columnOrder[fromIndex]);

    setColumnOrder(updatedOrder);
  };

  const handleRemoveColumn = async () => {
    try {
      await deleteRequest(`${baseUrl}/kanban/columns/${columnToDelete.numericId}`);

      setColumns(columns.filter((_, idx) => idx !== columnToDelete.index));

      onRemoveColumn(columnToDelete.id, columnToDelete.numericId);
    } catch (error) {
      onClose(({ showKanbanSettings: false }));
      setColumnToDeleteData((prev) => ({
        ...prev,
        showDeleteDialog: false,
      }));
      console.error('Error during column removal:', error);
      setErrorMessage('Erro ao remover a coluna. Entre em contato com o suporte.');
    }
  };

  return (
    <Container>
      <CustomAlert alert={alert} hideAlert={hideAlert} />

      <FormSection>
        <Label>Nome do quadro</Label>
        <Input
          type="text"
          value={boardName}
          onChange={(e) => setBoardName(e.target.value)}
          placeholder="Digite o nome do quadro"
        />
      </FormSection>

      <FormSection>
        <Label>Descrição</Label>
        <Input
          type="text"
          placeholder="Digite uma breve descrição do quadro"
          value={boardDescription}
          onChange={(e) => setBoardDescription(e.target.value)}
        />
      </FormSection>

      <FormSection>
        <Label>Colunas</Label>
        {columns.map((column, index) => (
          <ColumnForm
            key={column.id}
            column={column}
            index={index}
            moveColumn={moveColumn}
            handleColumnChange={handleColumnChange}
            handleOpenDeleteColumnDialog={handleOpenDeleteColumnDialog}
          />
        ))}

        <Button type="button" onClick={addColumn}>Adicionar Coluna</Button>
      </FormSection>

      <DeleteButton
        aria-label="Excluir quadro"
        type="button"
        onClick={handleShowDeleteBoardDialog}
      >
        <DeleteIcon />
        Excluir quadro
      </DeleteButton>

      <CustomDialog
        open={showDeleteDialog}
        onClose={handleCloseDeleteColumnDialog}
        onConfirm={handleRemoveColumn}
        message="Tem certeza que deseja remover"
        contactName="a coluna selecionada?"
        actionName="Remover"
        confirmButtonColor={theme.dangerButton}
      />

      <CustomDialog
        open={showDeleteBoardDialog}
        onClose={handleCloseDeleteBoardDialog}
        onConfirm={handleDeleteBoard}
        message="Tem certeza que deseja excluir"
        contactName={`o quadro "${currentKanban.name}" ?`}
        actionName="Excluir"
        confirmButtonColor={theme.dangerButton}
      />
    </Container>
  );
}

KanbanBoardSettings.propTypes = {
  currentKanban: PropTypes.shape({
    numericId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    columnOrder: PropTypes.arrayOf(PropTypes.string).isRequired,
    columns: PropTypes.objectOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        position: PropTypes.number.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onRemoveColumn: PropTypes.func.isRequired,
};

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 80%;
  width: 90%;
  justify-self: center;
  overflow-y: auto;
  margin: 20px;
  border: 1px solid #DDD;
  padding: 20px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.buttonBackground};

  &::-webkit-scrollbar {
  width: 10px;
  height: 6px;
  border: 1px solid #DDD;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: rgba(0,0,0,0.2);
  }
`;

export const FormSection = styled.div`
  margin-bottom: 20px;
  width: 100%;
`;

export const Label = styled.label`
  font-size: 18px;
  margin-bottom: 8px;
  font-weight: 500;
  display: block;
`;

export const Input = styled.input`
  padding: 10px;
  font-size: 16px;
  border: 1px solid #DDD;
  border-radius: 4px;
  width: 100%;
`;

export const Checkbox = styled.input`
  margin-right: 10px;
`;

export const Button = styled.button`
  padding: 10px 15px;
  font-weight: 500;
  background-color: ${({ theme }) => theme.secondaryColor};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.2s ease;

  svg {
    font-size: 20px;
  }

  &:hover {
    filter: brightness(0.8);
  }
`;

import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import PropTypes from 'prop-types';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Input, Button } from '../NewKanbanBoardStyles';

export default function ColumnForm({
  column,
  index,
  moveColumn,
  handleColumnChange,
  handleOpenDeleteColumnDialog,
}) {
  const [, drag] = useDrag({
    type: 'COLUMN',
    item: { index },
  });

  const [, drop] = useDrop({
    accept: 'COLUMN',
    hover: (item) => {
      if (item.index !== index) {
        moveColumn(item.index, index);
        // eslint-disable-next-line no-param-reassign
        item.index = index;
      }
    },
  });

  return (
    <div
      ref={(node) => drag(drop(node))}
      style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}
    >
      <Button
        ref={drag}
        style={{
          marginRight: '10px',
          cursor: 'move',
          padding: '8px 10px',
        }}
        aria-label="Mover coluna"
      >
        <DragIndicatorIcon />
      </Button>

      <Input
        type="text"
        placeholder="Nome da coluna"
        value={column.title}
        onChange={(e) => handleColumnChange(index, 'title', e.target.value)}
      />

      <Input
        type="number"
        placeholder="Ordem"
        value={column.position}
        readOnly
        style={{ width: '80px', marginLeft: '10px' }}
      />

      <Button
        variant="outlined"
        color="secondary"
        onClick={() => handleOpenDeleteColumnDialog(index, column)}
        style={{ marginLeft: '10px' }}
      >
        Remover
      </Button>
    </div>
  );
}

ColumnForm.propTypes = {
  column: PropTypes.shape({
    title: PropTypes.string.isRequired,
    position: PropTypes.number.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  moveColumn: PropTypes.func.isRequired,
  handleColumnChange: PropTypes.func.isRequired,
  handleOpenDeleteColumnDialog: PropTypes.func.isRequired,
};

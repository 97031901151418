/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import {
  useContext,
  useState,
} from 'react';
import ModalImage from 'react-modal-image';
import CheckIcon from '@mui/icons-material/Check';
import he from 'he';
import moment from 'moment';
import {
  Dialog,
  CircularProgress,
  DialogContent,
  Tooltip,
  Popover,
} from '@mui/material';

import PlayArrow from '@mui/icons-material/PlayArrow';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import TranscribeIcon from '@mui/icons-material/Transcribe';
import CloseIcon from '@mui/icons-material/Close';
import BlockIcon from '@mui/icons-material/Block';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import QuickreplyOutlinedIcon from '@mui/icons-material/QuickreplyOutlined';
import CustomDialog from '../CustomDialog';
import {
  deleteMessageUrl,
  formatPhoneNumber,
  postRequest,
  formatMessageBody,
} from '../../utils/services';
import { Container, ContactCard } from './styles';
import { AuthContext } from '../../context/AuthContext';
import { ChatContext } from '../../context/ChatContext';
import { useErrors } from '../../context/ErrorContext';

export default function ChatMessage({
  body,
  date,
  isReaded,
  idMsg,
  fromMe,
  isReceived,
  isDeleted,
  mediaType = null,
  mediaUrl = null,
  videoThumb = null,
  reacted = null,
  quotedMsgId = null,
}) {
  const { user } = useContext(AuthContext);
  const {
    currentChat,
    setMessages,
    quotedMessages,
    isMessagesLoading,
    quickAnswers,
    setQuickAnswers,
    audioTranscription,
    handleShowAudioTranscription,
  } = useContext(ChatContext);

  const { isLoadingTranscription, transcriptionText } = audioTranscription;

  const { setErrorMessage } = useErrors();

  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenQuickAnswer = (idMsg) => {
    setQuickAnswers({
      ...quickAnswers,
      messageIdToQuickAnswer: idMsg,
      showQuickAnswers: true,
    });

    setAnchorEl(null);
  };

  const handleOpenVideo = () => {
    setIsVideoOpen(true);
  };

  const handleCloseVideo = () => {
    setIsVideoOpen(false);
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
    handlePopoverClose();
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const token = user.senders[currentChat?.sender]?.sessionId;

  const transcriptionForThisMessage = transcriptionText.find(
    (transcription) => transcription.idMsg === idMsg,
  );

  const handleDeleteMessage = async () => {
    const response = await postRequest(
      deleteMessageUrl,
      JSON.stringify({
        token,
        fromMsg: currentChat.fromMsg,
        messageId: idMsg,
      }),
    );

    const cleanedResponse = response.split('{')[1].split('}')[0];
    const jsonResponse = JSON.parse(`{${cleanedResponse}}`);

    if (!jsonResponse || jsonResponse.error !== false) {
      setErrorMessage('Erro ao excluir mensagem. Entre em contato com o suporte');
      console.error('Error deleting message:', jsonResponse);
      return;
    }

    setMessages((prevMessages) => {
      const updatedMessages = prevMessages.map((message) => {
        if (message.idMsg === idMsg) {
          return { ...message, isDeleted: 1 };
        }
        return message;
      });

      return [...updatedMessages];
    });

    setIsDialogOpen(false);
  };

  const open = Boolean(anchorEl);

  const setAlignItems = () => {
    if (fromMe === 1 || fromMe === 3) return 'flex-end';
    if (fromMe === 0) return 'flex-start';
    return 'center';
  };

  const getMessageContentStyle = () => {
    if (fromMe === 2) {
      return {
        backgroundColor: '#DCE0E8',
        maxWidth: '100%',
        width: '90%',
        padding: '20px',
        textAlign: 'center',
        border: '1px solid #CFD4Df',
        boxShadow: 'none',
        margin: '20px 0',
      };
    }

    if (fromMe === 3) {
      return {
        backgroundColor: '#FFFF66',
        maxWidth: '50%',
        padding: '10px',
        textAlign: '',
        boxShadow: 'none',
      };
    }

    return {
      backgroundColor: fromMe === 1
        ? '#DCF8C6'
        : '#FFFFFF',
      maxWidth: '50%',
      padding: '10px',
    };
  };

  const formatContactCardBody = (body, mediaType) => {
    if (mediaType === 'contactMessage') {
      const nameMatch = body.match(/FN:([^;]*);/);
      const contactName = nameMatch ? nameMatch[1].trim() : '';
      const phoneMatches = [...body.matchAll(/waid=(\d+):/g)];

      return (
        <>
          <h1>{contactName}</h1>
          {phoneMatches.map((match, index) => (
            <span key={index}>
              {formatPhoneNumber(match[1])}
            </span>
          ))}
        </>
      );
    }
    return null;
  };

  const getSenderName = () => {
    if (fromMe === 0) {
      return currentChat.customName
      || currentChat.nome
      || formatPhoneNumber(currentChat.fromMsg);
    }
  };

  const getQuotedMessage = () => {
    return quotedMessages.find((message) => message.idMsg === quotedMsgId);
  };

  const renderQuotedMessage = () => {
    const quotedMessage = getQuotedMessage();
    if (quotedMessage) {
      return (
        <div className="quoted-message-box">
          <div className="left-bar" />
          <div className="quoted-message-content">
            <span className="sender-name">
              {quotedMessage.fromMe === 1 ? 'Você' : getSenderName(quotedMessage.fromMe)}
              {' '}
              {
              currentChat.nome && quotedMessage.fromMe === 0
                ? formatPhoneNumber(currentChat.fromMsg)
                : ''
              }
            </span>

            <div className="media-quoted-message-box">
              {quotedMessage.mediaType === 'contactMessage'
                ? (
                  <ContactCard>
                    {formatContactCardBody(he.decode(quotedMessage.body), quotedMessage.mediaType)}
                  </ContactCard>
                )
                : (
                  <span className="quoted-message-text">

                    {
                    formatMessageBody(he.decode(quotedMessage.body), quotedMessage.isDeleted)
                    }
                  </span>
                )}

              {quotedMessage.mediaType.startsWith('image') && (
              <div
                className="quoted-message-media-div"
                style={{
                  width: quotedMessage.mediaType === 'image/webp' ? '150px' : '300px',
                }}
              >
                <ModalImage
                  className="quoted-media"
                  small={quotedMessage.mediaUrl}
                  large={quotedMessage.mediaUrl}
                  alt="Imagem enviada pelo usuário"
                />
              </div>
              )}
              {quotedMessage.mediaType.startsWith('audio') && (
              <div style={{ maxWidth: '500px' }}>
                <audio controls>
                  <source src={quotedMessage.mediaUrl} type="audio/webm" />
                  <track kind="captions" src="" />
                </audio>
              </div>
              )}
              {quotedMessage.mediaType.startsWith('video') && (
              <div className="quoted-message-media-div">
                <video
                  className="quoted-media"
                  src={quotedMessage.mediaUrl}
                  controls
                  style={{
                    width: '100%',
                  }}
                >
                  <track kind="captions" srcLang="pt" label="Portuguese captions" />
                </video>

              </div>
              )}

              {quotedMessage.mediaType.startsWith('application') && (
              <div
                style={{
                  position: 'relative',
                  height: 'auto',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: 'auto',
                  }}
                >
                  <Tooltip
                    arrow
                    title="Baixar documento"
                  >
                    <a
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: '5px',
                        padding: '8px 12px',
                        borderRadius: '4px',
                        backgroundColor: '#014F6D',
                        color: '#F5F5F5',
                        textDecoration: 'none',
                      }}
                      href={quotedMessage.mediaUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Baixar documento"
                      download
                    >
                      <DownloadIcon
                        style={{
                          color: '#F5F5F5',
                          fontSize: '30px',
                        }}
                      />
                      Baixar documento
                    </a>
                  </Tooltip>
                </div>
              </div>
              )}

              {(quotedMessage.mediaType.startsWith('text/csv')
        || quotedMessage.mediaType.startsWith('text/plain')) && (
        <div
          className="quoted-message-media-div"
          style={{
            position: 'relative',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            <Tooltip
              arrow
              title="Baixar documento"
            >
              <a
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: '5px',
                  padding: '8px 12px',
                  borderRadius: '4px',
                  backgroundColor: '#014F6D',
                  color: '#F5F5F5',
                  textDecoration: 'none',
                }}
                href={quotedMessage.mediaUrl}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Baixar documento"
                download
              >
                <DownloadIcon
                  style={{
                    color: '#F5F5F5',
                    fontSize: '30px',
                  }}
                />
                Baixar documento
              </a>
            </Tooltip>
          </div>
        </div>
              )}

              { quotedMessage.fromMe !== 2 && (
              <span className="time-and-confirmation-span">
                {moment(quotedMessage.createdAt).calendar()}
                <span style={{ display: 'flex' }}>
                  {quotedMessage.fromMe === 1 && (
                  <CheckIcon style={{ color: quotedMessage.isReaded ? '#53BDEB' : '#919191', fontSize: '20px' }} />
                  )}
                  {quotedMessage.fromMe === 1 && quotedMessage.isReceived && (
                  <CheckIcon style={{ color: quotedMessage.isReaded ? '#53BDEB' : '#919191', fontSize: '20px', marginLeft: '-15px' }} />
                  )}
                </span>
              </span>
              )}

              {
          quotedMessage.reacted && (
            <Tooltip
              arrow
              title={he.decode(quotedMessage.reacted)}
            >
              <div
                style={{
                  width: '30px',
                  height: '30px',
                }}
                className="message-reacted-div"
              >
                {he.decode(quotedMessage.reacted)}
              </div>
            </Tooltip>
          )
        }

            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  const isAReactionMsg = mediaType === 'reactMsg';

  return !isAReactionMsg && (
    <Container style={{
      alignItems: setAlignItems(),
    }}
    >

      <div
        className="message-content-div"
        style={getMessageContentStyle()}
      >
        {fromMe === 1 && (
        <>
          <button
            aria-label="Abrir menu de mensagens"
            disabled={isMessagesLoading}
            type="button"
            className="message-menu-button"
            onClick={handlePopoverOpen}
          >
            <MoreHorizIcon aria-hidden={false} />
          </button>

          <Popover
            style={{
              maxWidth: '400px',
              width: 'auto',
            }}
            open={open}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <button
              onClick={handleDialogOpen}
              aria-label="Excluir mensagem"
              type="button"
              className="delete-message-button"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
                padding: '8px 10px',
                textAlign: 'center',
                fontSize: '16px',
                width: '100%',
                fontWeight: '600',
                transition: 'all 0.2s ease',
                backgroundColor: '#fff',
              }}
              onMouseEnter={(e) => { e.target.style.filter = 'brightness(0.9)'; }}
              onMouseLeave={(e) => { e.target.style.filter = 'brightness(1)'; }}
            >
              <DeleteOutlineIcon style={{
                fontSize: '20px',
                marginRight: '5px',
              }}
              />
              {' '}
              Apagar
            </button>
            <button
              onClick={() => handleOpenQuickAnswer(idMsg)}
              aria-label="Adicionar a respostas rápidas"
              type="button"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
                padding: '8px 10px',
                textAlign: 'center',
                fontSize: '16px',
                width: '300px',
                fontWeight: '600',
                transition: 'all 0.2s ease',
                backgroundColor: '#fff',
              }}
              onMouseEnter={(e) => { e.target.style.filter = 'brightness(0.9)'; }}
              onMouseLeave={(e) => { e.target.style.filter = 'brightness(1)'; }}
            >
              <QuickreplyOutlinedIcon style={{
                fontSize: '20px',
                marginRight: '5px',
              }}
              />
              Adicionar a respostas rápidas
            </button>

          </Popover>
        </>
        )}
        <div className="header-message-div">
          <h4>{getSenderName()}</h4>
          <span>
            {
            currentChat.nome && fromMe === 0
              ? formatPhoneNumber(currentChat.fromMsg)
              : ''
            }
          </span>
        </div>

        {mediaType === 'quotedMessage' && renderQuotedMessage()}

        {mediaType === 'contactMessage'
          ? (
            <ContactCard>
              {formatContactCardBody(he.decode(body), mediaType)}
            </ContactCard>
          )
          : (
            (mediaType.startsWith('audio') && body !== '')
              ? null
              : formatMessageBody(he.decode(body), isDeleted)
          )}

        {mediaType.startsWith('image') && (
          <div
            className="message-media-div"
            style={{
              width: mediaType === 'image/webp' ? '150px' : '300px',
            }}
          >
            <ModalImage
              small={mediaUrl}
              large={mediaUrl}
              alt="Imagem enviada pelo usuário"
            />
          </div>
        )}
        {mediaType.startsWith('audio') && (
          <>
            <div className="audio-player-div">
              <audio controls>
                <source src={mediaUrl} type="audio/webm" />
                <track kind="captions" src="" />
              </audio>

              {isLoadingTranscription ? (
                <Tooltip
                  placement="top"
                  arrow
                  title="Transcrição em andamento"
                >
                  <button
                    type="button"
                    disabled
                    aria-label="Transcrição em andamento"
                  >
                    <CircularProgress variant="indeterminate" size={24} />
                  </button>
                </Tooltip>

              ) : (
                <Tooltip
                  placement="top"
                  arrow
                  title={transcriptionForThisMessage?.showAudioTranscription
                    ? 'Fechar transcrição'
                    : 'Transcrever áudio'}
                >
                  <button
                    type="button"
                    aria-label={transcriptionForThisMessage?.showAudioTranscription
                      ? 'Fechar transcrição'
                      : 'Transcrever áudio'}
                    onClick={() => handleShowAudioTranscription(mediaUrl, idMsg, body)}
                  >
                    {transcriptionForThisMessage?.showAudioTranscription
                      ? <CloseIcon />
                      : <TranscribeIcon />}
                  </button>
                </Tooltip>
              )}
            </div>
            {transcriptionForThisMessage?.showAudioTranscription && (
            <div className="audio-transcription-div">
              <p className="transcription-text">
                “
                <em>{transcriptionForThisMessage.body}</em>
                ”
              </p>
            </div>
            )}
          </>
        )}
        {mediaType.startsWith('video') && (
          <div className="message-media-div">
            <button
              className="video-message-button"
              aria-label="Iniciar o vídeo"
              type="button"
              onClick={handleOpenVideo}
              style={{
                backgroundImage: `url(${videoThumb})`,
                backgroundSize: 'cover',
                position: 'relative',
                borderRadius: '4px',
                padding: '50px',
              }}
            >
              <PlayArrow />
            </button>

            <Dialog
              open={isVideoOpen}
              onClose={handleCloseVideo}
            >
              <DialogContent
                style={{
                  padding: '0px',
                  backgroundColor: 'black',
                }}
              >
                <video
                  src={mediaUrl}
                  controls
                  style={{
                    width: '100%',
                    height: '70vh',
                  }}
                  autoPlay
                >
                  <track kind="captions" srcLang="pt" label="Portuguese captions" />
                </video>
              </DialogContent>
            </Dialog>
          </div>
        )}

        {mediaType.startsWith('application') && (
        <div
          className="message-media-div"
          style={{
            position: 'relative',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            <Tooltip
              arrow
              title="Baixar documento"
            >
              <a
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: '5px',
                  padding: '8px 12px',
                  borderRadius: '4px',
                  backgroundColor: '#014F6D',
                  color: '#F5F5F5',
                  textDecoration: 'none',
                }}
                href={mediaUrl}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Baixar documento"
                download
              >
                <DownloadIcon
                  style={{
                    color: '#F5F5F5',
                    fontSize: '30px',
                  }}
                />
                Baixar documento
              </a>
            </Tooltip>
          </div>
        </div>
        )}

        {(mediaType.startsWith('text/csv') || mediaType.startsWith('text/plain')) && (
        <div
          className="message-media-div"
          style={{
            position: 'relative',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            <Tooltip
              arrow
              title="Baixar documento"
            >
              <a
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: '5px',
                  padding: '8px 12px',
                  borderRadius: '4px',
                  backgroundColor: '#014F6D',
                  color: '#F5F5F5',
                  textDecoration: 'none',
                }}
                href={mediaUrl}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Baixar documento"
                download
              >
                <DownloadIcon
                  style={{
                    color: '#F5F5F5',
                    fontSize: '30px',
                  }}
                />
                Baixar documento
              </a>
            </Tooltip>
          </div>
        </div>
        )}

        {isDeleted === 1 && (
        <span
          className="message-deleted-text"
        >
          <BlockIcon
            color="#919191"
            fontSize="12px"
          />

          Mensagem excluída pelo usuário
        </span>
        )}

        { fromMe !== 2 && (
        <span className="time-and-confirmation-span">
          {date}
          <span style={{ display: 'flex' }}>
            {fromMe === 1 && (
            <CheckIcon style={{ color: isReaded ? '#53BDEB' : '#919191', fontSize: '20px' }} />
            )}
            {fromMe === 1 && isReceived && (
            <CheckIcon style={{ color: isReaded ? '#53BDEB' : '#919191', fontSize: '20px', marginLeft: '-15px' }} />
            )}
          </span>
        </span>
        )}

        {
          reacted && (
            <Tooltip
              arrow
              title={he.decode(reacted)}
            >
              <div className="message-reacted-div">
                {he.decode(reacted)}
              </div>
            </Tooltip>
          )
        }
        <CustomDialog
          open={isDialogOpen}
          onClose={handleDialogClose}
          onConfirm={handleDeleteMessage}
          message="Tem certeza que deseja apagar essa mensagem?"
          actionName="Apagar"
          confirmButtonColor="red"
        />

      </div>

    </Container>
  );
}

ChatMessage.propTypes = {
  body: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  isReceived: PropTypes.bool.isRequired,
  isReaded: PropTypes.bool.isRequired,
  fromMe: PropTypes.number.isRequired,
  idMsg: PropTypes.string.isRequired,
  quotedMsgId: PropTypes.string,
  reacted: PropTypes.string,
  isDeleted: PropTypes.number,
  mediaUrl: PropTypes.string,
  mediaType: PropTypes.string,
  videoThumb: PropTypes.string,
};

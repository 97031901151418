import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { useDrop } from 'react-dnd';
import KanbanCard from './KanbanCard';
import {
  ColumnContainer,
  ColumnTitle,
  ColumnSubtitle,
  ColumnTopLine,
} from './KanbanColumnStyles';

export default function KanbanColumn({
  column, cards, onDrop, inputSearchForCards,
}) {
  const [{ isOver }, drop] = useDrop({
    accept: ['CARD', 'CHAT'],
    drop: (item) => {
      onDrop(item, column?.id);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const theme = useTheme();

  return (
    <ColumnContainer
      ref={drop}
      style={{
        background: isOver
          ? theme.buttonBackground
          : 'white',
      }}
    >
      <ColumnTopLine color={theme.secondaryColor} />

      <ColumnTitle>
        {column?.title}
      </ColumnTitle>

      {cards?.length > 0 && (
      <ColumnSubtitle>
        {cards?.length === 1
          ? '1 chat'
          : `${cards.length} chats`}
      </ColumnSubtitle>
      )}
      {cards?.filter((card) => card?.content.name.toLowerCase()
        .includes(inputSearchForCards.toLowerCase()))
        .sort((a, b) => {
          if (a.due_date && b.due_date) {
            return new Date(a.due_date) - new Date(b.due_date);
          } if (a.due_date) {
            return -1;
          } if (b.due_date) {
            return 1;
          }
          return 0;
        }).map((card) => (
          <KanbanCard
            key={card.id}
            card={card}
            columnId={column?.id}
          />
        ))}
    </ColumnContainer>
  );
}

KanbanColumn.propTypes = {
  column: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    cardsIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onDrop: PropTypes.func.isRequired,
  inputSearchForCards: PropTypes.string.isRequired,
};
